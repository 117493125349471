import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    images:[],
}


const getters = {
    getImages(state) {
        var result = state.images;
        return result;
    }
}

const actions = {
    addImage(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/gallery", payload).then(response => {
            context.commit('addImage',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateImage(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/gallery/"+payload.id, payload).then(response=>{
            context.commit('updateImage', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteImage(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/gallery/`+ payload.id).then(response=>{
            context.commit('deleteImage', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveImages(context) {
        context.commit('setLoading',true);
        return softsimApi.getRequest('/gallery' ).then(response => {
            context.commit('retrieveImages', response.data);
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addImage(state,payload){
        state.images.push(payload);
    },
    updateImage(state,payload){
        state.images = state.images.filter((image)=>{
            return image.id!=payload.id;
        });
        state.images.push(payload);
    },
    deleteImage(state,payload){
        state.images = state.images.filter((image)=>{
            return image.id!=payload.id;
        });
    },
    retrieveImages(state,payload){
        state.images=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
