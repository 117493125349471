import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    services:[],
}


const getters = {
    getServices(state) {
        var result = state.services;
        return result;
    }
}

const actions = {
    addService(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/service", payload).then(response => {
            context.commit('addService',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateService(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/service/"+payload.id, payload).then(response=>{
            context.commit('updateService', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteService(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/service/`+ payload.id).then(response=>{
            context.commit('deleteService', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveServices(context) {
        context.commit('setLoading',true);
        return softsimApi.getRequest('/service' ).then(response => {
            context.commit('retrieveServices', response.data);
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addService(state,payload){
        state.services.push(payload);
    },
    updateService(state,payload){
        state.services = state.services.filter((service)=>{
            return service.id!=payload.id;
        });
        state.services.push(payload);
    },
    deleteService(state,payload){
        state.services = state.services.filter((service)=>{
            return service.id!=payload.id;
        });
    },
    retrieveServices(state,payload){
        state.services=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
