import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    company_values:[],
}


const getters = {
    getCompanyValues(state) {
        var result = state.company_values;
        return result;
    }
}

const actions = {
    addCompanyValue(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/company_value", payload).then(response => {
            context.commit('addCompanyValue',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateCompanyValue(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/company_value/"+payload.id, payload).then(response=>{
            context.commit('updateCompanyValue', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteCompanyValue(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/company_value/`+ payload.id).then(response=>{
            context.commit('deleteCompanyValue', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveCompanyValues(context) {
        context.commit('setLoading',true);
        return softsimApi.getRequest('/company_value' ).then(response => {
            context.commit('retrieveCompanyValues', response.data);
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addCompanyValue(state,payload){
        state.company_values.push(payload);
    },
    updateCompanyValue(state,payload){
        state.company_values = state.company_values.filter((company_value)=>{
            return company_value.id!=payload.id;
        });
        state.company_values.push(payload);
    },
    deleteCompanyValue(state,payload){
        state.company_values = state.company_values.filter((company_value)=>{
            return company_value.id!=payload.id;
        });
    },
    retrieveCompanyValues(state,payload){
        state.company_values=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
