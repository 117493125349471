import Vue from 'vue';
import './plugins/axios'
import axios from 'axios';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import Vuetify from 'vuetify';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import store from './stores';
import { i18n } from "@/plugins/i18n";
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueParticles from 'vue-particles';
import swiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import lineClamp from 'vue-line-clamp';
import VueEllipseProgress from 'vue-ellipse-progress';
import IdleVue from "idle-vue";
import moment from "moment";
import 'tiptap-vuetify/dist/main.css';


const eventsHub = new Vue();
const vuetifylib = new Vuetify();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 120000, // 120 seconds
  startAtIdle: false
});
Vue.use(VueEllipseProgress);
Vue.use(lineClamp);
Vue.use(swiper);
Vue.use(VueParticles);
Vue.use(vuetifylib);
Vue.use(TiptapVuetifyPlugin, { vuetify: vuetifylib,iconsGroup: 'mdi' });
Swiper.use([Navigation, Pagination, Autoplay]);
        
Vue.config.productionTip = false;

//Custom console warnings for the user
console.clear();
console.log("%cStop!", "color: red; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px;");
console.log("%cThis window is only meant for project developers.", "color: gray; font-family: sans-serif; font-size: 1.5em; font-weight: bolder; text-shadow: #000 1px 1px;");
console.log("%cSOFTSIM","color:#0e3b40;font-size:100px;");

axios.interceptors.response.use(response => {
  return response;
}, error => {
  const{
    response: {status}
  } = error;
  if(status === 401) {
    store.dispatch('destroyToken');
    router.push({ name: "login" }).catch(() => {}); 
  }
})


Vue.prototype.$appName = 'SoftSim';
const DEFAULT_TITLE = 'Softsim Technologies Inc. | IT and Business Consulting';
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

router.beforeEach(async (to, from, next) => {
  if ((to.name === 'login') && store.getters.getToken) {
    router.push({
      name: 'admin'
    }).catch(() => {}); 

    //the route requires authentication
  } else if(to.meta.requiresAuth) {

    if (!store.getters.getToken) {

      //user not logged in, send them to login page 
      router.push({
        name: 'login',
        query: {
          to: to.name
        }
      }).catch(() => {}); 

    }
  }

  return next()
});
//Global array of supported languages and their abbriviations
Vue.prototype.$SupportedLanguages = [
  {name:'english',abbr:'en'},
  {name:'french',abbr:'fr'}
];

//Navigate to page based on path not name so we can default to custom error page if path is not found
Vue.prototype.$navigateToPath = (path) => {
  router.push({path:path}).catch(() => {})
};

//Filter Array and Return Object By Id
Vue.prototype.$getObjectById = (arr,id) => {
  return arr.filter(i=>{
    return i.id==id
  })[0];
};


Vue.filter('formatDate', function (date) {
  return date ? moment(date).format('MMMM D, YYYY') : ''
})

Vue.config.devtools = false;
Vue.prototype.$http = axios

new Vue({
  router,
  i18n,
  vuetify,
  store,
  mounted() {
    AOS.init({ 
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 0, // the delay on throttle used while scrolling the page (advanced)
      

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: -120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    })
  },
  render: h => h(App)
}).$mount('#app')