import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    companies:[],
}


const getters = {
    getCompanies(state) {
        var result = state.companies;
        return result;
    }
}

const actions = {
    addCompany(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/company", payload).then(response => {
            context.commit('addCompany',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateCompany(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/company/"+payload.id, payload).then(response=>{
            context.commit('updateCompany', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteCompany(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/company/`+ payload.id).then(response=>{
            context.commit('deleteCompany', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveCompanies(context) {
        context.commit('setLoading',true);
        return softsimApi.getRequest('/company' ).then(response => {
            context.commit('retrieveCompanies', response.data);
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addCompany(state,payload){
        state.companies.push(payload);
    },
    updateCompany(state,payload){
        state.companies = state.companies.filter((company)=>{
            return company.id!=payload.id;
        });
        state.companies.push(payload);
    },
    deleteCompany(state,payload){
        state.companies = state.companies.filter((company)=>{
            return company.id!=payload.id;
        });
    },
    retrieveCompanies(state,payload){
        state.companies=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
