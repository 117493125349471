import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    company_addresses:[],
}


const getters = {
    getCompanyAddresses(state) {
        var result = state.company_addresses;
        return result;
    }
}

const actions = {
    addCompanyAddress(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/company_address", payload).then(response => {
            context.commit('addCompanyAddress',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateCompanyAddress(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/company_address/"+payload.id, payload).then(response=>{
            context.commit('updateCompanyAddress', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteCompanyAddress(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/company_address/`+ payload.id).then(response=>{
            context.commit('deleteCompanyAddress', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveCompanyAddresses(context) {
        context.commit('setLoading',true);
        return softsimApi.getRequest('/company_address' ).then(response => {
            context.commit('retrieveCompanyAddresses', response.data);
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addCompanyAddress(state,payload){
        state.company_addresses.push(payload);
    },
    updateCompanyAddress(state,payload){
        state.company_addresses = state.company_addresses.filter((company_address)=>{
            return company_address.id!=payload.id;
        });
        state.company_addresses.push(payload);
    },
    deleteCompanyAddress(state,payload){
        state.company_addresses = state.company_addresses.filter((company_address)=>{
            return company_address.id!=payload.id;
        });
    },
    retrieveCompanyAddresses(state,payload){
        state.company_addresses=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
