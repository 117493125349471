import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    partners:[],
}


const getters = {
    getPartners(state) {
        var result = state.partners;
        return result;
    }
}

const actions = {
    addPartner(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/partner", payload).then(response => {
            context.commit('addPartner',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updatePartner(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/partner/"+payload.id, payload).then(response=>{
            context.commit('updatePartner', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deletePartner(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/partner/`+ payload.id).then(response=>{
            context.commit('deletePartner', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrievePartners(context) {
        context.commit('setLoading',true);
        return softsimApi.getRequest('/partner' ).then(response => {
            context.commit('retrievePartners', response.data);
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addPartner(state,payload){
        state.partners.push(payload);
    },
    updatePartner(state,payload){
        state.partners = state.partners.filter((partner)=>{
            return partner.id!=payload.id;
        });
        state.partners.push(payload);
    },
    deletePartner(state,payload){
        state.partners = state.partners.filter((partner)=>{
            return partner.id!=payload.id;
        });
    },
    retrievePartners(state,payload){
        state.partners=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
