import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

import contact from './modules/contact'
import client from './modules/client'
import partner from './modules/partner'
import blog from './modules/blog'
import product from './modules/product'
import product_service from './modules/product_service'
import product_attachment from './modules/product_attachment'
import statistic from './modules/statistic'
import service from './modules/service'
import gallery from './modules/gallery'
import opportunity from './modules/opportunity'
import company from './modules/company'
import company_address from './modules/company_address'
import company_social from './modules/company_social'
import company_value from './modules/company_value'

import softsimApi from "@/plugins/softsimApi";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    loading: false,
    LoggedIn: false,
    user: null,
    users: [],
  },
  plugins: [createPersistedState()],
  getters: {
    getUsers(state) {
      var result = state.users;
      return result;
    },
    isLoggedIn(state) {
      var result = state.LoggedIn;
      return result;
    },
    getToken(state) {
      var result = state.token;
      return result;
    },
    getUser(state) {
      var result = state.user;
      return result;
    },
  },
  actions: {
    retrieveToken(context,credentials) {
      context.commit('setLoading',true);
      return softsimApi.postRequest('/login', {
        username: credentials.username,
        password: credentials.password,
        }).then(response=>{
          context.commit('retrieveToken', response.data);
        return response;
      }).finally(() => {
        context.commit('setLoading',false);
    });
    },
    retrieveUsers(context) {
      context.commit('setLoading',true);
      return softsimApi.getRequest('user')
        .then(response => {
          context.commit('retrieveUsers', response.data);
          return response;
        }).finally(() => {
          context.commit('setLoading',false);
      });
    },
    retrieveUserInfo(context) {
      context.commit('setLoading',true);
      return softsimApi.getRequest('currentuser')
        .then(response => {
          context.commit('retrieveUserInfo', response.data);
          return response;
        }).finally(() => {
          context.commit('setLoading',false);
      });
    },
    destroyToken(context) {
      context.commit('setLoading',true);
      if (context.getters.isLoggedIn) {
        return new Promise((resolve, reject) => {
          softsimApi.postRequest('/logout')
            .then(response => {
              context.commit('destroyToken');
              context.commit('destroyUser');
              resolve(response)
            })
            .catch(error => {
              context.commit('destroyToken');
              context.commit('destroyUser');
              reject(error)
            }).finally(() => {
              context.commit('setLoading',false);
            });
        })
      }
    }
  },
  mutations: {
    setLoading(state,payload){
        state.loading=payload;
    },
    retrieveUsers(state, payload) {
      state.users = payload;
    },
    retrieveUserInfo(state, payload) {
      state.user = payload;
      state.LoggedIn=true;
    },
    retrieveToken(state, payload) {
      state.token = payload.access_token;
    },
    destroyToken(state) {
      state.token = null;
      state.LoggedIn=false;
    },
    destroyUser(state) {
      state.user = null;
      state.LoggedIn=false;
    },
  },

  modules: {
    contact,
    client,
    partner,
    blog,
    product,
    product_service,
    product_attachment,
    statistic,
    service,
    gallery,
    opportunity,
    company,
    company_address,
    company_social,
    company_value,
  }
})
