import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    statistics:[],
}


const getters = {
    getStatistics(state) {
        var result = state.statistics;
        return result;
    }
}

const actions = {
    addStatistic(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/statistic", payload).then(response => {
            context.commit('addStatistic',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateStatistic(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/statistic/"+payload.id, payload).then(response=>{
            context.commit('updateStatistic', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteStatistic(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/statistic/`+ payload.id).then(response=>{
            context.commit('deleteStatistic', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveStatistics(context) {
        context.commit('setLoading',true);
        return softsimApi.getRequest('/statistic' ).then(response => {
            context.commit('retrieveStatistics', response.data);
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addStatistic(state,payload){
        state.statistics.push(payload);
    },
    updateStatistic(state,payload){
        state.statistics = state.statistics.filter((statistic)=>{
            return statistic.id!=payload.id;
        });
        state.statistics.push(payload);
    },
    deleteStatistic(state,payload){
        state.statistics = state.statistics.filter((statistic)=>{
            return statistic.id!=payload.id;
        });
    },
    retrieveStatistics(state,payload){
        state.statistics=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
