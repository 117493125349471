import Vue from 'vue';
import Vuetify from 'vuetify/lib';
Vue.use(Vuetify);

export default new Vuetify({
    theme: { themes: {
        dark: {
            "custom-accent":"#0d3b3f",
            "custom-light-accent":"#344a4e",
            "custom-white":"#020202",
            "custom-off-white":"#090909",
            "custom-light-gray":"#060606",
            "custom-gray":"#040404",
            "custom-secondary":"#f9f9f9",
        },
        light: {
            "custom-accent":"#344a4e",
            "custom-light-accent":"#f1f6f7",
            "custom-white":"#FFFFFF",
            "custom-off-white":"#fdfdfd",
            "custom-light-gray":"#f8f8f8",
            "custom-gray":"#f0f0f0",
            "custom-secondary":"#323232",
        }
      },
      dark: false },
});