import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    company_socials:[],
}


const getters = {
    getCompanySocials(state) {
        var result = state.company_socials;
        return result;
    }
}

const actions = {
    addCompanySocial(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/company_social", payload).then(response => {
            context.commit('addCompanySocial',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateCompanySocial(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/company_social/"+payload.id, payload).then(response=>{
            context.commit('updateCompanySocial', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteCompanySocial(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/company_social/`+ payload.id).then(response=>{
            context.commit('deleteCompanySocial', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveCompanySocials(context) {
        context.commit('setLoading',true);
        return softsimApi.getRequest('/company_social' ).then(response => {
            context.commit('retrieveCompanySocials', response.data);
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addCompanySocial(state,payload){
        state.company_socials.push(payload);
    },
    updateCompanySocial(state,payload){
        state.company_socials = state.company_socials.filter((company_social)=>{
            return company_social.id!=payload.id;
        });
        state.company_socials.push(payload);
    },
    deleteCompanySocial(state,payload){
        state.company_socials = state.company_socials.filter((company_social)=>{
            return company_social.id!=payload.id;
        });
    },
    retrieveCompanySocials(state,payload){
        state.company_socials=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
