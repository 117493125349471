import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    product_attachments:[],
}


const getters = {
    getProductAttachments(state) {
        var result = state.product_attachments;
        return result;
    }
}

const actions = {
    addProductAttachment(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/product_attachment", payload).then(response => {
            context.commit('addProductAttachment',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateProductAttachment(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/product_attachment/"+payload.id, payload).then(response=>{
            context.commit('updateProductAttachment', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteProductAttachment(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/product_attachment/`+ payload.id).then(response=>{
            context.commit('deleteProductAttachment', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveProductAttachments(context) {
        context.commit('setLoading',true);
        return softsimApi.getRequest('/product_attachment' ).then(response => {
            context.commit('retrieveProductAttachments', response.data);
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    async downloadAttachment(context, payload) {
        context.commit('setLoading',true);
        return await softsimApi.getRequest('/product_attachment/download/' + payload.id,{responseType: 'blob'} ).then(response => {
            const url = window.URL
                .createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = payload.name
            link.target = '_blank'
            link.click();
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addProductAttachment(state,payload){
        state.product_attachments.push(payload);
    },
    updateProductAttachment(state,payload){
        state.product_attachments = state.product_attachments.filter((product_attachment)=>{
            return product_attachment.id!=payload.id;
        });
        state.product_attachments.push(payload);
    },
    deleteProductAttachment(state,payload){
        state.product_attachments = state.product_attachments.filter((product_attachment)=>{
            return product_attachment.id!=payload.id;
        });
    },
    retrieveProductAttachments(state,payload){
        state.product_attachments=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
