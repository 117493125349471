import axios from 'axios';
import store from '../stores';

axios.defaults.headers.common['Authorization'] = 'Bearer ';

export default {
    getRequest(...url){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getToken;
        return new Promise((resolve, reject) => {
            return axios.get(...url).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error)
            });
        })
    },
    postRequest(url,payload){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getToken;
        return new Promise( (resolve, reject)=>{
            return axios.post(url, payload).then( res => {
                resolve( res );
            }).catch(error => {
                reject(error)
            });
        })
    },
    patchRequest(url, payload){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getToken;
        return new Promise( (resolve, reject)=>{
            return axios.patch(url, payload).then( res => {
                resolve( res );
            }).catch(error => {
                reject(error)
            });
        })
    },
    deleteRequest(url){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getToken;
        return new Promise((resolve, reject) => {
            return axios.delete(url)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
