import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    clients:[],
}


const getters = {
    getClients(state) {
        var result = state.clients;
        return result;
    }
}

const actions = {
    addClient(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/client", payload).then(response => {
            context.commit('addClient',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateClient(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/client/"+payload.id, payload).then(response=>{
            context.commit('updateClient', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteClient(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/client/`+ payload.id).then(response=>{
            context.commit('deleteClient', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveClients(context) {
        context.commit('setLoading',true);
        return softsimApi.getRequest('/client' ).then(response => {
            context.commit('retrieveClients', response.data);
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addClient(state,payload){
        state.clients.push(payload);
    },
    updateClient(state,payload){
        state.clients = state.clients.filter((client)=>{
            return client.id!=payload.id;
        });
        state.clients.push(payload);
    },
    deleteClient(state,payload){
        state.clients = state.clients.filter((client)=>{
            return client.id!=payload.id;
        });
    },
    retrieveClients(state,payload){
        state.clients=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
