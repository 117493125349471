import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    products:[],
}


const getters = {
    getProducts(state) {
        var result = state.products;
        return result;
    }
}

const actions = {
    addProduct(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/product", payload).then(response => {
            context.commit('addProduct',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateProduct(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/product/"+payload.id, payload).then(response=>{
            context.commit('updateProduct', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteProduct(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/product/`+ payload.id).then(response=>{
            context.commit('deleteProduct', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveProducts(context) {
        context.commit('setLoading',true);
        return softsimApi.getRequest('/product' ).then(response => {
            context.commit('retrieveProducts', response.data);
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addProduct(state,payload){
        state.products.push(payload);
    },
    updateProduct(state,payload){
        state.products = state.products.filter((product)=>{
            return product.id!=payload.id;
        });
        state.products.push(payload);
    },
    deleteProduct(state,payload){
        state.products = state.products.filter((product)=>{
            return product.id!=payload.id;
        });
    },
    retrieveProducts(state,payload){
        state.products=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
